import React, { useState, useEffect } from "react";
import moment from "moment";
import { startOf, add } from "date-arithmetic";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { v4 as uuidv4 } from "uuid";
import ModalYear from "./ModalYear";
import PaperYear from "./PaperYear";
import PaperYearToRemove from "./PaperYearToRemove";
import { TbBeach } from "react-icons/tb";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

const PaperPopover = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
}));

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf("month");
  const last = currentDate.clone().endOf("month");
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set("date", day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
}

function CalendarDate(props) {
  const {
    dateToRender,
    dateOfMonth,
    events,
    onClick,
    setEvents,
    firstClickDate,
    setMousePosition,
    isEditable,
    handleClickToSHowList,
    infoTypeRequest,
    infoEmployee,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  let today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";

  const handleMouseEnter = (event) => {
    if (firstClickDate && !isHovered) {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);

      const intervalDates = [];
      let currentDay = startDate.clone();
      while (currentDay.isSameOrBefore(endDate, "day")) {
        intervalDates.push(currentDay.format("YYYY-MM-DD"));
        currentDay.add(1, "day");
      }

      setIsHovered(true);

      // Criar um novo evento do tipo "hover" para todo o intervalo de datas
      const hoverEvents = intervalDates.map((date) => ({
        id: uuidv4(),
        start: moment(date).toDate(),
        end: moment(date).endOf("day").toDate(),
        type: "hover",
        title: "Hovered Day",
        color: "#44A49B",
      }));

      setEvents((prevEvents) => [...prevEvents, ...hoverEvents]);

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          intervalDates.includes(moment(event.start).format("YYYY-MM-DD"))
            ? { ...event, hover: true }
            : event
        )
      );
    }
    setMousePosition({ x: event.pageX, y: event.pageY });
  };
  const handleMouseLeave = () => {
    if (firstClickDate && isHovered) {
      setIsHovered(false);

      setEvents((prevEvents) => {
        // Filtra os eventos de "hover" para remover apenas aqueles do intervalo
        const updatedEvents = prevEvents.filter((event) =>
          event.type === "hover"
            ? moment(event.start).isBefore(firstClickDate) ||
              moment(event.start).isSameOrAfter(dateToRender)
            : true
        );

        return updatedEvents.map((event) =>
          event.hover ? { ...event, hover: false } : event
        );
      });
    } else {
      today =
        dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ? "today"
          : "";
    }
  };
  // const dayOfWeek = moment(dateToRender).day();
  // const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

  // if (isWeekend) {
  //   return (
  //     <button
  //       className="date in-month"
  //       style={{
  //         background: "rgba(226, 226, 226,0.6)",
  //         color: "rgb(171, 171, 171)",
  //         cursor: "default",
  //         // border: "2px solid rgb(171, 171, 171)",
  //       }}
  //     >
  //       {dateToRender.date()}
  //     </button>
  //   );
  // }

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  const matchedEvent = events.filter((eventDetail) =>
    dateToRender.isSame(moment(eventDetail.start), "day")
  );

  if (matchedEvent.length === 0) {
    matchedEvent.push(
      events.find((eventDetail) =>
        dateToRender.isSame(moment(eventDetail.start), "day")
      )
    );
  }

  const eventosDoDia = (date) =>
    events.filter(
      (evento) =>
        evento.start.getDate() === date.getDate() &&
        evento.start.getMonth() === date.getMonth() &&
        evento.start.getFullYear() === date.getFullYear()
    );
  const filteredEvents =
    matchedEvent.length > 0 &&
    matchedEvent.filter((event) => event && event.type !== "diasAprovar");
  const lastEvent = matchedEvent[matchedEvent.length - 1] || {};

  // const currentYear = moment().year();
  // const currentMonth = moment().month(); // Janeiro é 0, Fevereiro é 1, etc.
  // const currentDay = moment().date(); // Dia do mês

  // const dateMoment = moment(dateToRender);
  // const dateYear = dateMoment.year();
  // const dateMonth = dateMoment.month();
  // const dateDay = dateMoment.date();

  // const isBeforeYear = dateYear > currentYear;
  // const isBeforeMonth = dateYear === currentYear && dateMonth > currentMonth;
  // const isBeforeDay =
  //   dateYear === currentYear &&
  //   dateMonth === currentMonth &&
  //   dateDay >= currentDay;

  // const cursorStyle =
  //   isBeforeYear || isBeforeMonth || isBeforeDay ? "pointer" : "default";

  // const backgroundStyle =
  //   isBeforeYear || isBeforeMonth || isBeforeDay ? "" : "transparent";

  const elementStyle = {
    // cursor: cursorStyle,
    padding:
      matchedEvent[0]?.type == "feriasmarked" ||
      matchedEvent[0]?.type == "intervals" ||
      matchedEvent[0]?.type == "ferias"
        ? "1px"
        : "",
    // backgroundColor: backgroundStyle,
  };

  return (
    <Badge
      color="warning"
      badgeContent={filteredEvents.length}
      invisible={filteredEvents.length <= 1 || isEditable}
      variant="dot"
      classes={{ badge: "badgeYearCalendar" }}
    >
      <button
        className={`date in-month ${lastEvent.type || today} ${
          lastEvent.hover && lastEvent.type === "hover" ? "highlighted" : ""
        } ${
          lastEvent.parteDia === "1"
            ? "meio-dia1parte"
            : lastEvent.parteDia === "2"
            ? "meio-dia2parte"
            : ""
        }${
          lastEvent.type === "ferias" &&
          lastEvent.state &&
          lastEvent.title === infoEmployee
            ? lastEvent.state === 1
              ? "pendente"
              : lastEvent.state === 2
              ? "rejeitado"
              : lastEvent.state === 3
              ? "aprovado"
              : ""
            : ""
        } ${lastEvent.toCancel ? "toCancel" : ""}`}
        title={
          matchedEvent
            ? matchedEvent
                .filter((event) => event && event.name)
                .map((event) => event.name)
                .join(",\n")
            : ""
        }
        onClick={(e) => {
          if (isEditable) {
            // if (isBeforeYear || isBeforeMonth || isBeforeDay) {
            onClick(dateToRender);
            // }
          } else {
            if (lastEvent.start !== undefined) {
              const eventos = eventosDoDia(matchedEvent && lastEvent.start);
              if (eventos.length > 0) {
                handleClickToSHowList(e, eventos);
              }
            }
          }
        }}
        onMouseEnter={(e) => {
          if (isEditable) {
            handleMouseEnter(e);
          }
        }}
        onMouseLeave={() => {
          if (isEditable) {
            handleMouseLeave();
          }
        }}
        style={elementStyle}
      >
        {matchedEvent[0]?.type == "feriados" ? "F" : dateToRender.date()}
      </button>
    </Badge>
  );
}

function CalendarComponent({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  convertToEventsMarkedDaysToApprove,
  settoastText,
  setShowToast,
  t,
  isEditable,
  singleDaySelection,
  setRemoveAll,
  setRemoveSomeDays,
  userNamePortalRH,
  anchorEl,
  setAnchorEl,
  open,
  handleClickToSHowList,
  infoTypeRequest,
  infoEmployee,
}) {
  const [calendar, setCalendar] = useState(null);
  const [firstClickDate, setFirstClickDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dateToCompare, setDateToCompare] = useState(undefined);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [eventToRemove, setEventToRemove] = useState(null);

  useEffect(() => {
    setCalendar(createCalendar(date));
  }, [date]);

  if (!calendar) {
    return null;
  }

  const handleClick = (day, event) => {
    setDateToCompare(day);
    if (event) {
      setEventToRemove(event);
    }
    const { x, y } = mousePosition;

    const top = y;
    const left = x;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: left });
  };

  const handleAddIntervalEvent = (dateToRender) => {
    const isEventAlreadyExists = events.some(
      (event) =>
        ["feriados", "folgas", "ferias"].includes(event.type) &&
        moment(event.start).isSame(dateToRender, "day")
    );
    const isEventAlreadyMarked = events.some(
      (event) =>
        ["feriasmarked", "intervals"].includes(event.type) &&
        moment(event.start).isSame(dateToRender, "day")
    );
    if (isEventAlreadyExists) {
      return;
    }
    if (isEventAlreadyMarked) {
      const isEventSolo = events.some(
        (event) =>
          ["feriasmarked", "intervals"].includes(event.type) &&
          moment(event.start).isSame(dateToRender, "day") &&
          event.multi === true
      );
      handleClick(dateToRender);

      return;
    }
    if (!firstClickDate) {
      const singleDayEvent = {
        id: uuidv4(),
        start: moment(dateToRender).toDate(),
        end: moment(dateToRender).endOf("day").toDate(),
        type: "intervals",
        title: t("portalrh.workDays.selectedDay"),
        color: "#44A49B",
        intervals: [],
        checked: true,
        checkedHalfDay: false,
        multi: true,
        parteDia: "0",
        empConsumoSaldo: "0",
      };
      setEvents((prevEvents) => [...prevEvents, singleDayEvent]);
      setFirstClickDate(moment(dateToRender));
    } else {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);
      const intervals = [];
      let currentDay = startDate.clone();
      while (currentDay.isSameOrBefore(endDate, "day")) {
        const formattedDate = currentDay.format("YYYY-MM-DD");
        const dateAlreadyExists = events.some(
          (event) =>
            [
              "feriados",
              "folgas",
              "ferias",
              "intervals",
              "feriasmarked",
              "feriasPendentes",
              "datasMarcacoes",
              "datasGozados",
              "diasAprovarCancelar",
              "diasAprovar",
            ].includes(event.type) &&
            moment(event.start).format("YYYY-MM-DD") === formattedDate
        );
        if (!dateAlreadyExists) {
          intervals.push(formattedDate);
        }
        currentDay.add(1, "day");
      }

      const intervalEvents = intervals.map((intervalDate) => ({
        id: uuidv4(),
        start: moment(intervalDate).toDate(),
        end: moment(intervalDate).endOf("day").toDate(),
        type: "intervals",
        title: "Hovered Interval",
        color: "#44A49B",
        multi: true,
        checked: true,
        checkedHalfDay: false,
        parteDia: "0",
        empConsumoSaldo: "0",
      }));

      setEvents((prevEvents) => [...prevEvents, ...intervalEvents]);
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.type !== "hover")
      );

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          moment(event.start).isSame(firstClickDate, "day")
            ? { ...event, intervals }
            : event
        )
      );

      setFirstClickDate(null);
      setRemoveAll(true);
    }
  };

  const handleAddSingleEvent = (date) => {
    const isEventAlreadyExists = events.some(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type !== "intervals" &&
        moment(event.start).isSame(date, "day")
    );

    const eventFound = events.find(
      (event) =>
        (event.type === "ferias" ||
          event.type === "datasMarcacoes" ||
          event.type === "feriasPendentes") &&
        moment(event.start).isSame(date, "day")
    );

    if (isEventAlreadyExists) {
      if (eventFound) {
        if (eventFound.type === "feriasPendentes") {
          handleClick(eventFound.start, eventFound);
        } else {
          setRemoveSomeDays(eventFound);
        }
        return;
      } else {
        return;
      }
    }

    if (isEditable) {
      const daysMarked = convertToEventsMarkedDays(
        [date],
        "feriasmarked",
        "#E6CD32"
      );

      const isEventSolo = events.some(
        (event) =>
          ["intervals"].includes(event.type) &&
          moment(event.start).isSame(daysMarked[0].start, "day") &&
          event.multi === true
      );

      const isEventSoloFeriasMarked = events.some(
        (event) =>
          ["feriasmarked"].includes(event.type) &&
          moment(event.start).isSame(daysMarked[0].start, "day") &&
          event.multi === true
      );

      const isHoveredInterval = events.some(
        (event) =>
          event.title === "Hovered Interval" &&
          moment(event.start).isSame(date, "day")
      );

      if (isEventSolo || isHoveredInterval) {
        handleClick(daysMarked[0].start);
      } else if (isEventSoloFeriasMarked) {
        const firstDayMarkedStart = new Date(daysMarked[0].start).getTime();
        setEvents((prevEvents) =>
          prevEvents.filter(
            (event) => new Date(event.start).getTime() !== firstDayMarkedStart
          )
        );
      } else {
        setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
        setRemoveAll(true);
      }
    }
  };

  const lang = calendar.currentDate._locale._abbr;
  const weekdays = {
    pt: ["D", "S", "T", "Q", "Q", "S", "S"],
    en: ["S", "M", "T", "W", "T", "F", "S"],
    es: ["D", "L", "M", "X", "J", "V", "S"],
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setDateToCompare(undefined);
    setEventToRemove(null);
    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  return (
    <>
      <div className="month">
        <div className="month-name">
          {calendar.currentDate.format("MMMM").toUpperCase()}
        </div>
        {weekdays[lang].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {calendar.map((week, index) => (
          <div key={index}>
            {week.map((date) => (
              <CalendarDate
                key={date.date()}
                isEditable={isEditable}
                dateToRender={date}
                dateOfMonth={calendar.currentDate}
                onClick={(date) => {
                  if (isEditable) {
                    !singleDaySelection
                      ? handleAddIntervalEvent(date)
                      : handleAddSingleEvent(date);
                  }
                }}
                events={events}
                setEvents={setEvents}
                firstClickDate={firstClickDate}
                setMousePosition={setMousePosition}
                handleClickToSHowList={handleClickToSHowList}
                infoTypeRequest={infoTypeRequest}
                infoEmployee={infoEmployee}
              />
            ))}
          </div>
        ))}
      </div>
      {dateToCompare !== undefined && (
        <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <PaperPopover elevation={8} variant="elevation">
            {eventToRemove === null ? (
              <PaperYear
                events={events}
                dateToCompare={dateToCompare}
                handleAddIntervalEvent={handleAddIntervalEvent}
                setEvents={setEvents}
                singleDaySelection={singleDaySelection}
                handleClosePopOver={handleClosePopOver}
              />
            ) : (
              <PaperYearToRemove
                events={events}
                setEvents={setEvents}
                handleClosePopOver={handleClosePopOver}
                eventToRemove={eventToRemove}
                dateToCompare={dateToCompare}
                setRemoveSomeDays={setRemoveSomeDays}
                userNamePortalRH={userNamePortalRH}
              />
            )}
          </PaperPopover>
        </Popover>
      )}
      {/* 
      <ModalYear
        events={events}
        showModal={showModal}
        dateToCompare={dateToCompare}
        handleAddIntervalEvent={handleAddIntervalEvent}
        setShowModal={setShowModal}
        setEvents={setEvents}
        singleDaySelection={singleDaySelection}
      /> */}
    </>
  );
}
function Year({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  convertToEventsMarkedDaysToApprove,
  settoastText,
  setShowToast,
  t,
  isEditable,
  singleDaySelection,
  setRemoveAll,
  setRemoveSomeDays,
  userNamePortalRH,
  handleClickToSHowList,
  infoTypeRequest,
  infoEmployee,
}) {
  let range = Year.range(date);
  const months = [];
  const firstMonth = startOf(date, "year");
  const singleDayValue = singleDaySelection === "single" ? true : false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  for (let i = 0; i < 12; i++) {
    months.push(
      <CalendarComponent
        key={i + 1}
        date={add(firstMonth, i, "month")}
        events={events}
        setEvents={setEvents}
        convertToEventsMarkedDays={convertToEventsMarkedDays}
        convertToEventsMarkedDaysToApprove={convertToEventsMarkedDaysToApprove}
        settoastText={settoastText}
        setShowToast={setShowToast}
        isEditable={isEditable}
        singleDaySelection={singleDayValue}
        setRemoveAll={setRemoveAll}
        setRemoveSomeDays={setRemoveSomeDays}
        userNamePortalRH={userNamePortalRH}
        t={t}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        handleClickToSHowList={handleClickToSHowList}
        infoTypeRequest={infoTypeRequest}
        infoEmployee={infoEmployee}
      />
    );
  }

  return (
    <div className="year scrollBar-visible-year">
      {months.map((month) => month)}
    </div>
  );
}

Year.range = (date) => {
  return [startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, "year");

    case navigate.NEXT:
      return add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

export default Year;
