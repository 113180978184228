import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";
import { Card, Button } from "react-bootstrap";
import {
  getPeriodicidadeListDropdown,
  getDatasListDropdown,
  getRegraListDropdown,
} from "./utils";

import {
  getEmailTemplates,
  countEmailTemplates,
  handleEmailTemplate,
  getEventTypesData,
  errorMessageVariable,
} from "~/store/ducks/udw/actionTypes";

import { getOperations } from "~/store/ducks/portalrh/actionTypes";

import EmailTemplateTable from "./EmailTemplateTable";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import Messages from "~/components/Messages";
import SpinnerNew from "~/components/SpinnerNew";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import NavTabs from "../../../NavTabs";

const EmailTemplate = ({ t, portalRhMenus, activeSelected, designacao }) => {
  //dispatch and reducers
  const dispatch = useDispatch();
  const {
    emailTemplates,
    isLoadingEmailTemplates,
    nEmailTemplates,
    editingEmailTemplates,
    eventTypesData,
  } = useSelector((state) => state.udwReducer);
  const { operations } = useSelector((state) => state.portalrhReducer);

  //Message Variables
  const msgVars = [
    "§sender§",
    "§receiver§",
    "§timeSensitiveGreetings§",
    "§documentTitle§",
    "§documentType§",
    "§operation§",
    "§date§",
    "§employeeNum§",
  ];
  //cursor position in textareas
  const subjectRef = useRef();
  const messageRef = useRef();

  const dateOwnSubjectRef = useRef();
  const dateOwnMessageRef = useRef();
  const dateOthersSubjectRef = useRef();
  const dateOthersMessageRef = useRef();

  //state variables
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedOperationId, setSelectedOperationId] = useState("");
  const [selectedDocTypeId, setSelectedDocTypeId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [focus, setFocus] = useState("");

  const [validated, setValidated] = useState(false);
  const [operationError, setOperationError] = useState();
  const [docTypeError, setDocTypeError] = useState();

  const [resetDocTypeDropdown, setResetDocTypeDropdown] = useState(false);

  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [periodicidade, setPeriodicidade] = useState("");
  const [periodicidadeError, setPeriodicidadeError] = useState();
  const [fromTemplate, setFromTemplate] = useState("");
  const [fromTemplateError, setFromTemplateError] = useState();
  const [datas, setDatas] = useState(null);
  const [datasError, setDatasError] = useState();
  const [numberDays, setNumberDays] = useState("");
  const [resetDropdown, setResetDropdown] = useState(false);
  const [resetDropdownPeriodicidade, setResetDropdownPeriodicidade] =
    useState(false);

  const [subjectOwn, setSubjectOwn] = useState("");
  const [messageOwn, setMessageOwn] = useState("");
  const [subjectOthers, setSubjectOthers] = useState("");
  const [messageOthers, setMessageOthers] = useState("");
  const [dateForPeriodicidade, setDateForPeriodicidade] = useState("");

  const [variables, setVariables] = useState([]);
  const [notificationNot, setNotificationNot] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [regra, setRegra] = useState("");
  const [regraError, setRegraError] = useState();
  const [resetDropdownRegra, setResetDropdownRegra] = useState(false);
  const [contextValue, setContextValue] = useState("1");

  useEffect(() => {
    if (contextValue === "1") {
      setVariables([...msgVars]);
    } else {
      if (datas !== null) {
        const event = eventTypesData.find(
          (event) => event.idEventoData.toString() === datas
        );
        if (event && event.eventoVariaveis) {
          const eventoVariaveisValues = event.eventoVariaveis.map(
            (ev) => ev.eventoVariavel.value
          );
          setVariables([...eventoVariaveisValues]);
        } else {
          setVariables([...msgVars]);
        }
      } else {
        setVariables([...msgVars]);
      }
    }
  }, [contextValue, datas, eventTypesData]);

  //useEfects
  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
  }, []);
  useEffect(() => {
    dispatch(getEmailTemplates(PORTALRH_MIDDLEWARE.context, t));
    dispatch(getOperations(t));
    dispatch(countEmailTemplates());
    dispatch(getEventTypesData());
  }, []);

  useEffect(() => {
    if (
      (!showCreateModal && !showUpdateModal && !showDeleteModal) ||
      editingEmailTemplates
    ) {
      dispatch(getEmailTemplates(PORTALRH_MIDDLEWARE.context, t));
      dispatch(countEmailTemplates());
      resetEmailTemplateValues();
    }
    if (showUpdateModal || showDeleteModal) {
      fillEmailTemplateValues();
    }
  }, [
    showCreateModal,
    showUpdateModal,
    showDeleteModal,
    editingEmailTemplates,
  ]);
  useEffect(() => {
    setOperationError(selectedOperationId === "" ? true : false);
    setDocTypeError(selectedDocTypeId === "" ? true : false);
  }, [selectedDocTypeId, selectedOperationId]);

  useEffect(() => {
    setPeriodicidadeError(periodicidade === "" ? true : false);
    setDatasError(datas === null ? true : false);
    setRegraError(regra === "" ? true : false);
  }, [periodicidade, datas, regra]);

  //Setup modal's values --------------------------------------------
  const resetEmailTemplateValues = () => {
    setSelectedEmailTemplate(undefined);
    setSelectedOperationId("");
    setSelectedDocTypeId("");
    setSubject("");
    setMessage("");
    setFocus("");
    setValidated(false);
    setOperationError();
    setDocTypeError();
  };
  const fillEmailTemplateValues = () => {
    setSelectedOperationId(selectedEmailTemplate?.operationId);
    setSelectedDocTypeId(selectedEmailTemplate?.docTypeId);
    setSubject(selectedEmailTemplate?.subject);
    setMessage(selectedEmailTemplate?.message);
    setPeriodicidade(
      selectedEmailTemplate?.emailMessageDTO?.periodicidade?.trim() || ""
    );
    setDatas(selectedEmailTemplate?.emailMessageDTO?.idEventoData);
    setNumberDays(selectedEmailTemplate?.emailMessageDTO?.numeroDias);
    setSubjectOwn(selectedEmailTemplate?.subjectOwn);
    setMessageOwn(selectedEmailTemplate?.messageOwn);
    setSubjectOthers(selectedEmailTemplate?.subject);
    setMessageOthers(selectedEmailTemplate?.message);
    setDateForPeriodicidade(selectedEmailTemplate?.dateForPeriodicidade);
    setNotificationNot(selectedEmailTemplate?.notificationNot);
    setEmailNotification(selectedEmailTemplate?.emailNotification);
    setRegra(selectedEmailTemplate?.emailMessageDTO?.regra);
  };

  //create modal --------------------------------------------------------------------
  const closeCreateModal = () => {
    setShowCreateModal(false);
    resetValuesDataTemplate();
    dispatch(errorMessageVariable(false));
  };
  const openCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleSaveEmailTemplate = (templateId = null) => {
    const isUpdate = templateId !== null;
    const id = isUpdate ? templateId : null;

    const udwDTO = {
      emailTemplateId: id,
      subjectOwn: contextValue === "1" ? subject : subjectOwn,
      messageOwn: contextValue === "1" ? message : messageOwn,
      isDoc: contextValue === "1",
      ...(contextValue === "1" && {
        operationId: selectedOperationId,
        docTypeId: selectedDocTypeId,
      }),
      ...(contextValue !== "1" && {
        subjectOthers: subjectOthers,
        messageOthers: messageOthers,
        periodicidade: periodicidade,
        numberDays: numberDays,
        notificationNot: notificationNot,
        emailNotification: emailNotification,
        regra: regra,
        eventoDataId: datas,
      }),
      appLabel: PORTALRH_MIDDLEWARE.context,
    };
    dispatch(
      handleEmailTemplate(
        isUpdate ? "put" : "post",
        udwDTO,
        {},
        PORTALRH_MIDDLEWARE.context,
        null
      )
    );

    isUpdate ? closeUpdateModal() : closeCreateModal();
  };
  //update modal ---------------------------------------------------------------------
  const closeUpdateModal = () => {
    setShowUpdateModal(false);
    resetValuesDataTemplate();
    dispatch(errorMessageVariable(false));
  };
  const openUpdateModal = (emailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    //TODO:ALTERAR AQUI PARA FAZER SET DAS VARS
    setShowUpdateModal(true);
  };

  //Deletion modal -------------------------------------------------------------------
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    resetValuesDataTemplate();
    dispatch(errorMessageVariable(false));
  };
  const openDeleteModal = (emailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    setShowDeleteModal(true);
  };

  //Message Variables ----------------------------------------------------------------
  const addMessageVariable = (string) => {
    if (focus === "subject") {
      setSubject(
        subject.substring(0, subjectRef.current.selectionStart) +
          string +
          subject.substring(subjectRef.current.selectionStart, subject.length)
      );
    } else if (focus === "message") {
      setMessage(
        message.substring(0, messageRef.current.selectionStart) +
          string +
          message.substring(messageRef.current.selectionStart, message.length)
      );
    } else if (focus === "subjectOwn") {
      setSubjectOwn(
        subjectOwn.substring(0, dateOwnSubjectRef.current.selectionStart) +
          string +
          subjectOwn.substring(
            dateOwnSubjectRef.current.selectionStart,
            subjectOwn.length
          )
      );
    } else if (focus === "messageOwn") {
      setMessageOwn(
        messageOwn.substring(0, dateOwnMessageRef.current.selectionStart) +
          string +
          messageOwn.substring(
            dateOwnMessageRef.current.selectionStart,
            messageOwn.length
          )
      );
    } else if (focus === "subjectOthers") {
      setSubjectOthers(
        subjectOthers.substring(
          0,
          dateOthersSubjectRef.current.selectionStart
        ) +
          string +
          subjectOthers.substring(
            dateOthersSubjectRef.current.selectionStart,
            subjectOthers.length
          )
      );
    } else if (focus === "messageOthers") {
      setMessageOthers(
        messageOthers.substring(
          0,
          dateOthersMessageRef.current.selectionStart
        ) +
          string +
          messageOthers.substring(
            dateOthersMessageRef.current.selectionStart,
            messageOthers.length
          )
      );
    }
  };
  const createMessageVariableButtons = () => {
    const msgVarButtons = msgVars.map((el) => (
      <Button
        key={el + "MsgVarButton"}
        className="message-variable-btn"
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => addMessageVariable(el, subjectRef, messageRef)}
      >
        {t("portalrh.emailTemplate.messageVariables." + el)}
      </Button>
    ));
    return msgVarButtons;
  };

  const createDateMessageVariableButtons = () => {
    const msgVarButtons = variables.map((el) => (
      <Button
        key={el + "MsgVarButton"}
        className="message-variable-btn"
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => addMessageVariable(el)}
      >
        {t("portalrh.emailTemplate.messageVariables." + el)}
      </Button>
    ));
    return msgVarButtons;
  };
  //Form submissions and validations ----------------------------------------------------
  const handleDropdownClassName = (varError) => {
    return (
      "dropdown-margin " +
      (varError === true && validated
        ? "dropdown-border-invalid"
        : varError === false && validated
        ? "dropdown-border-valid"
        : "")
    );
  };
  const handleSubmit = (event, toDelete) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (toDelete) {
      dispatch(
        handleEmailTemplate(
          "delete",
          {},
          {},
          PORTALRH_MIDDLEWARE.context,
          selectedEmailTemplate.id
        )
      );
      closeDeleteModal();
    } else {
      if (
        !form.checkValidity() ||
        operationError ||
        docTypeError ||
        periodicidadeError ||
        datasError ||
        regraError
      ) {
        handleSaveEmailTemplate(
          contextValue === "1"
            ? selectedEmailTemplate?.templateId
            : selectedEmailTemplate.id
        );
        event.stopPropagation();
      } else {
        handleSaveEmailTemplate(
          contextValue === "1"
            ? selectedEmailTemplate?.templateId
            : selectedEmailTemplate.id
        );
      }
      setValidated(true);
    }
  };

  const handleSetSelectedOperationId = (id) => {
    if (id !== selectedOperationId) {
      setSelectedOperationId(id);
      setSelectedDocTypeId("");
      setResetDocTypeDropdown(true);
    }
  };

  const handlePeriodicidadeSelect = (value) => {
    if (value === "none") setDateForPeriodicidade("");
    else setNumberDays("");
    setPeriodicidade(value);
  };

  const handleRegraSelect = (value) => {
    setRegra(value);
  };

  const handleNotificationType = (from) => {
    if (from === "email") {
      setEmailNotification((prev) => !prev);
    } else if (from === "notificacao") {
      setNotificationNot((prev) => !prev);
    }
  };

  const handleDatasSelect = (value) => {
    if (value !== datas) {
      dispatch(errorMessageVariable(false));
      createDateMessageVariableButtons();
      setResetDropdownPeriodicidade(true);
      setResetDropdownRegra(true);
      setDatas(value);
      setPeriodicidade("");
      setNumberDays("");
      setDateForPeriodicidade("");
      setRegra("");
    }
  };

  const resetValuesDataTemplate = () => {
    setResetDropdown(true);
    setResetDropdownPeriodicidade(true);
    setResetDropdownRegra(true);
    setPeriodicidade("");
    setRegra("");
    setDatas(null);
    setNumberDays("");
    setSubjectOwn("");
    setMessageOwn("");
    setSubjectOthers("");
    setMessageOthers("");
    setDateForPeriodicidade("");
    setNotificationNot(false);
    setEmailNotification(false);
  };

  const formattedEventTypes = () => {
    if (eventTypesData.length === 0) {
      return [];
    }
    return eventTypesData.map((event) => ({
      label: t(event.descricao),
      value: event.idEventoData.toString(),
      selected: datas === event.idEventoData,
    }));
  };

  const periodicidadeListDropdown = getPeriodicidadeListDropdown(t);
  const regraListDropdown = getRegraListDropdown(t);

  const handleChangeTab = (event, newValue) => {
    resetValuesDataTemplate();
    setContextValue(newValue);
    dispatch(errorMessageVariable(false));
  };

  return (
    <>
      <div className="main-card-v2" style={{ width: "100%" }}>
        <Card
          bsPrefix="card-flat"
          style={{ minHeight: "80vh", overflow: "auto" }}
        >
          <Card.Header className="justify-content-between">
            <NavTabs
              titleMenu={t("portalrh.emailTemplate.title")}
              menus={menus}
              activeSelected={activeSelected}
              containMenu={containMenu}
              setIsLoading={setIsLoading}
              t={t}
            />
          </Card.Header>
          <Card.Body>
            <div className="custom-table custom-table-small cursor-pointer">
              <EmailTemplateTable
                openCreateModal={openCreateModal}
                openUpdateModal={openUpdateModal}
                openDeleteModal={openDeleteModal}
                emailTemplates={emailTemplates}
                isLoadingEmailTemplates={isLoadingEmailTemplates}
                contextValue={contextValue}
                handleChangeTab={handleChangeTab}
              />

              <CreateModal
                //General modal funcionalities
                createMessageVariableButtons={createMessageVariableButtons}
                createDateMessageVariableButtons={
                  createDateMessageVariableButtons
                }
                setFocus={setFocus}
                //Specific modal functionalities
                showCreateModal={showCreateModal}
                closeCreateModal={closeCreateModal}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                //Validation Funcionalities
                handleDropdownClassName={handleDropdownClassName}
                handleSubmit={handleSubmit}
                handleSetSelectedOperationId={handleSetSelectedOperationId}
                //validation data
                validated={validated}
                setValidated={setValidated}
                operationError={operationError}
                docTypeError={docTypeError}
                //Reset docType dropdown when needed
                resetDocTypeDropdown={resetDocTypeDropdown}
                setResetDocTypeDropdown={setResetDocTypeDropdown}
                //track cursor position in textareas
                subjectRef={subjectRef}
                messageRef={messageRef}
                periodicidadeListDropdown={periodicidadeListDropdown}
                handlePeriodicidadeSelect={handlePeriodicidadeSelect}
                periodicidade={periodicidade}
                fromTemplate={fromTemplate}
                periodicidadeError={periodicidadeError}
                fromTemplateError={fromTemplateError}
                datasListDropdown={formattedEventTypes}
                handleDatasSelect={handleDatasSelect}
                datas={datas}
                datasError={datasError}
                numberDays={numberDays}
                setNumberDays={setNumberDays}
                resetDropdown={resetDropdown}
                setResetDropdown={setResetDropdown}
                resetDropdownPeriodicidade={resetDropdownPeriodicidade}
                setResetDropdownPeriodicidade={setResetDropdownPeriodicidade}
                resetValuesDataTemplate={resetValuesDataTemplate}
                dateOwnSubjectRef={dateOwnSubjectRef}
                dateOwnMessageRef={dateOwnMessageRef}
                dateOthersSubjectRef={dateOthersSubjectRef}
                dateOthersMessageRef={dateOthersMessageRef}
                subjectOwn={subjectOwn}
                messageOwn={messageOwn}
                subjectOthers={subjectOthers}
                messageOthers={messageOthers}
                setSubjectOwn={setSubjectOwn}
                setMessageOwn={setMessageOwn}
                setSubjectOthers={setSubjectOthers}
                setMessageOthers={setMessageOthers}
                dateForPeriodicidade={dateForPeriodicidade}
                setDateForPeriodicidade={setDateForPeriodicidade}
                notificationNot={notificationNot}
                emailNotification={emailNotification}
                handleNotificationType={handleNotificationType}
                regraListDropdown={regraListDropdown}
                regraError={regraError}
                handleRegraSelect={handleRegraSelect}
                resetDropdownRegra={resetDropdownRegra}
                setResetDropdownRegra={setResetDropdownRegra}
                contextValue={contextValue}
                setContextValue={setContextValue}
                regra={regra}
              />

              <UpdateModal
                //General modal funcionalities
                createMessageVariableButtons={createMessageVariableButtons}
                setFocus={setFocus}
                //Specific modal functionalities
                selectedEmailTemplate={selectedEmailTemplate}
                showUpdateModal={showUpdateModal}
                closeUpdateModal={closeUpdateModal}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                //Validation Funcionalities
                handleDropdownClassName={handleDropdownClassName}
                handleSubmit={handleSubmit}
                handleSetSelectedOperationId={handleSetSelectedOperationId}
                //validation data
                validated={validated}
                setValidated={setValidated}
                operationError={operationError}
                docTypeError={docTypeError}
                //Reset docType dropdown when needed
                resetDocTypeDropdown={resetDocTypeDropdown}
                setResetDocTypeDropdown={setResetDocTypeDropdown}
                //track cursor position in textareas
                subjectRef={subjectRef}
                messageRef={messageRef}
                createDateMessageVariableButtons={
                  createDateMessageVariableButtons
                }
                periodicidadeListDropdown={periodicidadeListDropdown}
                handlePeriodicidadeSelect={handlePeriodicidadeSelect}
                periodicidade={periodicidade}
                fromTemplate={fromTemplate}
                periodicidadeError={periodicidadeError}
                fromTemplateError={fromTemplateError}
                datasListDropdown={formattedEventTypes}
                handleDatasSelect={handleDatasSelect}
                datas={datas}
                datasError={datasError}
                numberDays={numberDays}
                setNumberDays={setNumberDays}
                resetDropdown={resetDropdown}
                setResetDropdown={setResetDropdown}
                resetDropdownPeriodicidade={resetDropdownPeriodicidade}
                setResetDropdownPeriodicidade={setResetDropdownPeriodicidade}
                resetValuesDataTemplate={resetValuesDataTemplate}
                dateOwnSubjectRef={dateOwnSubjectRef}
                dateOwnMessageRef={dateOwnMessageRef}
                dateOthersSubjectRef={dateOthersSubjectRef}
                dateOthersMessageRef={dateOthersMessageRef}
                subjectOwn={subjectOwn}
                messageOwn={messageOwn}
                subjectOthers={subjectOthers}
                messageOthers={messageOthers}
                setSubjectOwn={setSubjectOwn}
                setMessageOwn={setMessageOwn}
                setSubjectOthers={setSubjectOthers}
                setMessageOthers={setMessageOthers}
                dateForPeriodicidade={dateForPeriodicidade}
                setDateForPeriodicidade={setDateForPeriodicidade}
                notificationNot={notificationNot}
                emailNotification={emailNotification}
                handleNotificationType={handleNotificationType}
                regraListDropdown={regraListDropdown}
                regraError={regraError}
                handleRegraSelect={handleRegraSelect}
                resetDropdownRegra={resetDropdownRegra}
                setResetDropdownRegra={setResetDropdownRegra}
                contextValue={contextValue}
                regra={regra}
              />

              <DeleteModal
                //Specific modal functionalities
                selectedEmailTemplate={selectedEmailTemplate}
                showDeleteModal={showDeleteModal}
                closeDeleteModal={closeDeleteModal}
                //Email template data
                operations={operations}
                selectedOperationId={selectedOperationId}
                setSelectedDocTypeId={setSelectedDocTypeId}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                createDateMessageVariableButtons={
                  createDateMessageVariableButtons
                }
                handleDropdownClassName={handleDropdownClassName}
                periodicidadeListDropdown={periodicidadeListDropdown}
                handlePeriodicidadeSelect={handlePeriodicidadeSelect}
                periodicidade={periodicidade}
                fromTemplate={fromTemplate}
                periodicidadeError={periodicidadeError}
                fromTemplateError={fromTemplateError}
                datasListDropdown={formattedEventTypes}
                handleDatasSelect={handleDatasSelect}
                datas={datas}
                datasError={datasError}
                numberDays={numberDays}
                setNumberDays={setNumberDays}
                resetDropdown={resetDropdown}
                setResetDropdown={setResetDropdown}
                resetDropdownPeriodicidade={resetDropdownPeriodicidade}
                setResetDropdownPeriodicidade={setResetDropdownPeriodicidade}
                resetValuesDataTemplate={resetValuesDataTemplate}
                dateOwnSubjectRef={dateOwnSubjectRef}
                dateOwnMessageRef={dateOwnMessageRef}
                dateOthersSubjectRef={dateOthersSubjectRef}
                dateOthersMessageRef={dateOthersMessageRef}
                subjectOwn={subjectOwn}
                messageOwn={messageOwn}
                subjectOthers={subjectOthers}
                messageOthers={messageOthers}
                setSubjectOwn={setSubjectOwn}
                setMessageOwn={setMessageOwn}
                setSubjectOthers={setSubjectOthers}
                setMessageOthers={setMessageOthers}
                dateForPeriodicidade={dateForPeriodicidade}
                setDateForPeriodicidade={setDateForPeriodicidade}
                toDelete={true}
                setFocus={setFocus}
                handleSubmit={handleSubmit}
                handleSetSelectedOperationId={handleSetSelectedOperationId}
                messageRef={messageRef}
                subjectRef={subjectRef}
                validated={validated}
                setResetDocTypeDropdown={setResetDocTypeDropdown}
                resetDocTypeDropdown={resetDocTypeDropdown}
                docTypeError={docTypeError}
                operationError={operationError}
                notificationNot={notificationNot}
                emailNotification={emailNotification}
                handleNotificationType={handleNotificationType}
                regraListDropdown={regraListDropdown}
                regraError={regraError}
                handleRegraSelect={handleRegraSelect}
                resetDropdownRegra={resetDropdownRegra}
                setResetDropdownRegra={setResetDropdownRegra}
                contextValue={contextValue}
                regra={regra}
              />

              <SpinnerNew open={editingEmailTemplates} />
              <Messages />
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default withNamespaces()(EmailTemplate);
