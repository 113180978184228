import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import SpinnerNew from "~/components/SpinnerNew";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";

import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";

const EmailTemplateTable = (props) => {
  const {
    openCreateModal,
    openUpdateModal,
    openDeleteModal,
    emailTemplates,
    isLoadingEmailTemplates,
    handleChangeTab,
    contextValue,
    t,
  } = props;

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    if (contextValue === "1") {
      setDataForTable(emailTemplates.filter((template) => template.docType));
    } else {
      setDataForTable(emailTemplates.filter((template) => !template.docType));
    }
  }, [contextValue, emailTemplates]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const buildActionsColumn = (_, row, {}) => (
    <>
      <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
        <i
          className="icon-trash table-action-icon"
          onClick={() => openDeleteModal(row)}
        />
        <i
          className="icon-edit table-action-icon"
          onClick={() => openUpdateModal(row)}
        />
      </Stack>
    </>
  );

  const { SearchBar } = Search;
  const columnsDoc = [
    {
      dataField: "id",
      text: t("portalrh.emailTemplate.id"),
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "operation",
      text: t("portalrh.emailTemplate.operation"),
      sort: true,
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "docType",
      text: t("portalrh.emailTemplate.docType"),
      sort: true,
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "subject",
      text: t("portalrh.emailTemplate.subject"),
      sort: true,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "accao",
      text: t("admin.userManagement.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const columnsDate = [
    {
      dataField: "id",
      text: t("portalrh.emailTemplate.id"),
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "emailMessageDTO.dataDescricao",
      text: t("portalrh.emailTemplate.tipoDataTemplate"),
      sort: true,
      formatter: (cell) => t(cell),
    },
    {
      dataField: "subjectOwn",
      text: t("portalrh.emailTemplate.subjectOwn"),
      sort: true,
    },
    {
      dataField: "subject",
      text: t("portalrh.emailTemplate.subjectOthers"),
      sort: true,
    },
    {
      dataField: "emailMessageDTO.numeroDias",
      text: t("portalrh.emailTemplate.numeroDias"),
      sort: true,
    },

    {
      dataField: "emailMessageDTO.periodicidade",
      text: t("portalrh.emailTemplate.periodicidade"),
      formatter: (cell) => {
        const periodicidade = cell !== undefined ? cell : "";
        switch (periodicidade.toString().trim()) {
          case "1":
            return t("portalrh.emailTemplate.dataTemplate.anual");
          case "2":
            return t("portalrh.emailTemplate.dataTemplate.mensal");
          case "3":
            return t("portalrh.emailTemplate.dataTemplate.none");
          default:
            return "OLA";
        }
      },
      sort: true,
    },
    {
      dataField: "emailMessageDTO.regra",
      text: t("portalrh.emailTemplate.regra"),
      formatter: (cell) => {
        switch (cell) {
          case "1":
            return t("portalrh.emailTemplate.dataTemplate.onFlow");
          case "2":
            return t("portalrh.emailTemplate.dataTemplate.notSend");
          default:
            return cell;
        }
      },
      sort: true,
    },
    {
      dataField: "accao",
      text: t("admin.userManagement.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const columns = contextValue === "1" ? columnsDoc : columnsDate;

  const mobileColumns = [
    {
      dataField: "info",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const headers = formatExtraData.headers;
        return (
          <div>
            <br />
            {headers &&
              headers.map((header, index) => {
                const key = header.dataField;
                if (key && row[key] !== undefined && !header.hidden) {
                  const value =
                    typeof row[key] === "boolean"
                      ? row[key].toString()
                      : row[key];
                  return (
                    <div key={index}>
                      <b>{header.text}: </b>
                      {value}
                    </div>
                  );
                }
                return null;
              })}
          </div>
        );
      },
      formatExtraData: {
        headers: columns,
      },
    },
    {
      dataField: "accao",
      text: "",
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
  ];

  //result
  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={dataForTable}
        columns={isMobile ? mobileColumns : columns}
        search
      >
        {(props) => (
          <div>
            <div style={{ paddingBottom: "20px", display: "flex" }}>
              <SearchBar {...props.searchProps} placeholder={t("Search")} />
              <div
                style={{
                  paddingLeft: "30px",
                  placeContent: "space-between",
                  display: "flex",
                  width: "-webkit-fill-available",
                }}
              >
                <ButtonAdd
                  text={
                    contextValue == "1"
                      ? t("general.addDocTemplate")
                      : t("general.addDataTemplate")
                  }
                  onClick={openCreateModal}
                />
                <ButtonGroup variant="text" aria-label="Basic button group">
                  <Button
                    onClick={(event) => handleChangeTab(event, "1")}
                    style={{
                      backgroundColor:
                        contextValue === "1" ? "whitesmoke" : "transparent",
                      textTransform: "none",
                    }}
                  >
                    {t("portalrh.emailTemplate.docTemplate")}
                  </Button>
                  <Button
                    onClick={(event) => handleChangeTab(event, "2")}
                    style={{
                      backgroundColor:
                        contextValue === "2" ? "whitesmoke" : "transparent",
                      textTransform: "none",
                    }}
                  >
                    {t("portalrh.emailTemplate.dateTemplate")}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            {isLoadingEmailTemplates ? (
              <SpinnerNew open={isLoadingEmailTemplates} />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(
                  isMobile ? mobilePagination : pagination
                )}
                noDataIndication={t("No team requests available")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};
export default withNamespaces()(EmailTemplateTable);
