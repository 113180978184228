import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postEsliFlow } from "~/pages/Login/actions";

import SpinnerNew from "~/components/SpinnerNew";
import { checkToken } from "~/store/ducks/oauth/actionTypes";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const { isAuthenticated, esliflow } = useSelector(
    (state) => state.loginReducer
  ); 
  const { isCheckingToken } = useSelector((state) => state.oauthReducer);
    const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (isAuthenticated === undefined) {
      dispatch(checkToken());
    }
    if (esliflow !== undefined) {
      const payload = {
        userId: user.id,
        esliflow: esliflow,
      };
      dispatch(postEsliFlow(payload));
    }
  }, [isAuthenticated, esliflow, dispatch]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : isCheckingToken !== undefined && !isCheckingToken ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          <SpinnerNew open={isCheckingToken} />
        )
      }
    />
  );
};

export default ProtectedRoute;
