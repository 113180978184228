import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "../../../utils/config/http";

import {
  successRequestUser,
  failRequestUser,
  setUserOrganization,
} from "../actions";

import { setUserSettings } from "~/pages/User/actions";

import oauth from "~/utils/oauth";
import history from "~/utils/config/history";

export function* requestUser() {
  try {
    const token = oauth.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let resp = yield http.get("/api/user/me", config);
    yield put(successRequestUser(resp.data));
    const organizationId = resp.data?.organization?.id;
    resp = yield http.get(`/api/mongo/user/${resp.data?.username}`, config);
    yield put(setUserSettings(resp.data));
    if (organizationId) {
      resp = yield http.get(`/api/organization/find-by-id/${organizationId}`, config);
      yield put(setUserOrganization(resp.data));
    }
  } catch (error) {
    console.error("[MongoDB]: Not connected!");
    console.error("-------------------------------------------");
    console.error("|                                          |");
    console.error("|               MONGO OFF                  |");
    console.error("|                                          |");
    console.error("-------------------------------------------");
    oauth.logout();
    history.push("/");
    yield put(failRequestUser());
    reject(error);
  }
}